<template>
    <div>
        <v-dialog v-model="dialog" max-width="1200px" persistent scrollable>
        <v-card>
          <v-card-title class="dialog_card_title">
            <span class="headline font-weight-medium"> {{ title }}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-container grid-list-md>

                <v-layout row wrap>
                    <v-flex xs12 sm12 md12 lg12>
                       <h1 class="title">Pogoji</h1>
                        <div class="div__divider__15"></div>

                       <h3>Zasebnost</h3>
                       <div class="div__divider__5"></div> 
                       <p class="body-2 font-weight-regular card--text">Zavezani smo, da bomo ščitili vašo zasebnost. Nenehno pregledujemo sistem in podatke, da bi svojim strankam zagotovili najboljšo možno storitev.</p>

                       <h3>Zaupnost</h3> 
                       <div class="div__divider__5"></div> 
                        <p class="body-2 font-weight-regular card--text">Vaše osebne podatke ne bomo prodajali, dali v skupno rabo ali dajali v najem tretjim osebam ali uporabili vaš e-poštni naslov za neželeno pošto. Vsa e-poštna sporočila, ki jih pošljemo, bodo povezana le z zagotavljanjem dogovorjenih storitev in izdelkov.</p>

                       <h3>Izključitve in omejitve</h3>
                       <div class="div__divider__5"></div> 
                       <p class="body-2 font-weight-regular card--text">Informacije na tej spletni strani so podane na podlagi “kot je”. V največji meri, ki jo dopušča zakon ne vključujemo vseh navedb in jamstev, povezanih s to spletno stranjo in njeno vsebino, lahko jih zagotavlja katera koli podružnica ali katera koli druga tretja oseba. Enako velja za kakršne koli nepravilnosti ali opustitvijo spletne strani in/ali literature; izključujemo vso odgovornost za škodo, ki izhaja v zvezi z vašo uporabo te spletne strani.</p>

                       <h3>Razpoložljivost</h3>
                       <div class="div__divider__5"></div> 
                       <p class="body-2 font-weight-regular card--text">Družba ne jamči, da bo storitev s tega spletnega mesta neprekinjena, pravočasna ali brez napak, čeprav je zagotovljena z najboljšimi zmožnostmi.</p>

                       <h3>Odgovornost za povezave</h3>
                       <div class="div__divider__5"></div> 
                       <p class="body-2 font-weight-regular card--text">Naša spletna stran vsebuje povezave do zunanjih spletnih strani, nad katerimi nimamo nadzora, zato ne prevzemamo odgovornosti za njihovo vsebino. </p>
                       <p class="body-2 font-weight-regular card--text">Ne spremljamo ali pregledujemo vsebine drugih spletnih strani, ki so povezane s to spletno stranjo. Vsebina ali izražena mnenja, ki se pojavljajo na teh spletnih straneh, nas nujno ne podpirajo in jih ne smemo obravnavati kot izdajatelje takšnih mnenj ali gradiva. Povezana spletna mesta so bila za morebitne kršitve preverjena v času povezovanja. Nezakonite vsebine so bile v času povezovanja upoštevane. Upoštevajte, da nismo odgovorni za prakse zasebnosti ali vsebino teh spletnih mest.</p>
                       <p class="body-2 font-weight-regular card--text">Ko vzpostavite povezavo z drugo spletno stranjo, niste več na naši spletni strani zato veljajo novi pravilniki o zasebnosti na novem spletnem mestu. Ne prevzemamo nikakršne odgovornosti za kakršnokoli izgubo ali škodo na kakršenkoli način, ne glede na to, kakšne so posledice vašega razkritja osebnih podatkov tretjim osebam. Stalni nadzor povezanih strani je nerazumen, brez konkretnih dokazov o kršitvi. Po obvestilu o morebitnih kršitvah bomo te povezave takoj odstranili.</p>

                       <h3>Komunikacija</h3>
                       <div class="div__divider__5"></div> 
                       <p class="body-2 font-weight-regular card--text">Za različne poizvedbe imamo več različnih e-poštnih naslovov. Kontaktni podatki so na voljo tukaj.</p>

                       <h3>Spremembe</h3>
                       <div class="div__divider__5"></div> 
                       <p class="body-2 font-weight-regular card--text">Pridržujemo si pravico, da občasno spremenimo te pogoje, kot nam to ustreza. Vaša nadaljnja uporaba spletne strani pomeni, da se strinjate s kakršnimi koli prilagoditvami teh pogojev. Če pride do sprememb v našem pravilniku o zasebnosti, bomo sporočili, da so bile te spremembe na naši spletni strani in na drugih ključnih straneh našega spletnega mesta prilagojene.</p>
                       <p class="body-2 font-weight-regular card--text">Če pride do spremembe v tem, kako bomo uporabili osebne podatke o naših straneh, bodo obvestila po e-pošti poslana tistim, na katere ta sprememba vpliva. Vse spremembe v našem pravilniku o zasebnosti bodo predhodno objavljene na naši spletni strani.</p>
                       <p class="body-2 font-weight-regular card--text">Vsi pogoji uporabe spletne strani so sestavni del sporazuma. Vaš dostop do spletne strani kaže na vaše razumevanje, strinjanje in sprejemanje vseh navedenih pogojev.</p>

                       <h1 class="title">Varstvo podatkov</h1>
                       <div class="div__divider__15"></div>

                        <h3>Uvod</h3>
                        <div class="div__divider__5"></div> 
                        <p class="body-2 font-weight-regular card--text">Uporaba naše spletne strani je ponavadi mogoča brez posredovanja osebnih podatkov. Z izrecnim soglasjem se občasno zbirajo osebni podatki (npr. ime, naslov ali e-poštni naslov), ki jih posredujete prostovoljno.</p>
                        <p class="body-2 font-weight-regular card--text">Poudarjamo, da lahko prenos podatkov prek interneta (npr. komunikacija po elektronski pošti) povzroči varnostne vrzeli. Popolno varstvo podatkov pred nepooblaščenim dostopom s strani tretjih oseb je nemogoče.</p>

                        <h3>Dnevnik</h3>
                        <div class="div__divider__5"></div> 
                        <p class="body-2 font-weight-regular card--text">Beležimo IP-naslove za analizo trendov, upravljanje spletne strani, spremljanje gibanja uporabnika in zbiranje širokih demografskih podatkov za skupno uporabo. IP-naslovi niso povezani z osebnimi podatki. Poleg tega za upravljanje sistemov, zaznavanje vzorcev uporabe in odpravljanje napak naši spletni strežniki samodejno prijavijo standardne informacije o dostopu. Ob vsakem pozivu na spletno stran bodo podatki o dostopu shranjeni v datoteko dnevnika, tako imenovani dnevnik strežnika. Shranjeni podatki bodo vsebovali naslednje podatke:</p>
                        <ul>
                            <li><p class="body-2 font-weight-regular card--text">datum in čas</p></li>
                            <li><p class="body-2 font-weight-regular card--text">naslov IP</p></li>
                            <li><p class="body-2 font-weight-regular card--text">seja ID</p></li>
                            <li><p class="body-2 font-weight-regular card--text">priklicana spletna stran</p></li>
                            <li><p class="body-2 font-weight-regular card--text">ime spletnega mesta, iz katerega je bilo pokrito naše spletno mesto</p></li>
                            <li><p class="body-2 font-weight-regular card--text">uporabljen brskalnik</p></li>
                        </ul>
                        <p class="body-2 font-weight-regular card--text">Te datoteke dnevnika bomo analizirali le v primeru zlorabe našega spletnega mesta. Zagotovili bomo, da bomo retrospektivno pregledali datoteke dnevnika uporabnikov, posebej osumljenih nezakonite uporabe spletne strani in / ali kršitve pogojev.</p>

                        <h3>Piškotki</h3>
                        <div class="div__divider__5"></div> 
                        <p class="body-2 font-weight-regular card--text">Ta spletna stran uporablja “piškotke”, ki omogočajo, da je naša spletna stran na splošno bolj uporabniku prijazna, učinkovita in varna – na primer, ko gre za pospešitev navigacije. Piškotki omogočajo tudi merjenje pogostosti ogledov strani in splošne navigacije. Piškotki so majhne besedilne datoteke, ki so shranjene v vašem računalniku. Upoštevajte, da so nekateri od teh piškotkov preneseni v vaš računalniški sistem iz našega strežnika; to so običajno “pišoči seji”. Za piškotke seje je značilno, da se po končanem brskalnem sejanju samodejno izbrišejo s trdega diska. Drugi piškotki ostanejo v vašem računalniku in nam omogočajo, da prepoznate svoj računalniški sistem ob naslednjem obisku (tako imenovani vztrajni piškotki). Piškotke lahko kadar koli zavrnete, če vaš brskalnik to dovoljuje.</p>

                        <h3>Zakaj so piškotki potrebni?</h3>
                        <div class="div__divider__5"></div> 
                        <p class="body-2 font-weight-regular card--text">So temeljnega pomena za zagotavljanje uporabniku prijaznih spletnih storitev. Interakcija med spletnim uporabnikom in spletnim mestom je s pomočjo piškotkov hitrejša in preprostejša. Z njihovo pomočjo si spletno mesto zapomni posameznikove preference in izkušnje, s tem je prihranjen čas, brskanje po spletnih mestih pa bolj učinkovito in prijazno.</p>
                        <p class="body-2 font-weight-regular card--text">Podjetje vsakemu uporabniku ob začetku vsakokratne uporabe spletne trgovine dodeli piškotek za identifikacijo, spremljanje nakupovalne košarice in zagotavljanje sledljivosti, ki se v pomnilniku strežnika shranjuje le za čas trajanja obiska spletne trgovine in se izbriše po eni uri neaktivnosti. Podjetje lahko na osebni računalnik uporabnika shrani tudi nekatere trajne piškotke, kot npr. identifikacijska številka uporabnika v šifrirani obliki za prepoznavanje ob naslednjem obisku spletne trgovine ali ocene artiklov, s pomočjo katerega uporabnik ve, katere artikle je že ocenil, posredno pa tudi piškotke zunanje storitve Google Analytics, ki služijo analizi obiskov spletnega mesta. Podjetje lahko te podatke v anonimizirani sumarni obliki uporabi za namene statističnih analiz. Za potrebe zagotavljanja spletne varnosti podjetje zbira tudi IP naslove, iz katerih uporabniki dostopajo do spletne trgovine.</p>

                        <h3>Onemogočanje piškotkov</h3>
                        <div class="div__divider__5"></div> 
                        <p class="body-2 font-weight-regular card--text">Vi odločite, ali boste dovolili shranjevanje piškotkov na vaši napravi. Nastavitve za piškotke lahko nadzirate in spreminjate v vašem spletnem brskalniku. Za informacije o nastavitvah za piškotke izberite spletni brskalnik, ki ga uporabljate:</p>
                        <ul>
                            <li><p class="body-2 font-weight-regular card--text">Chrome</p></li>
                            <li><p class="body-2 font-weight-regular card--text">Firefox</p></li>
                            <li><p class="body-2 font-weight-regular card--text">Opera</p></li>
                            <li><p class="body-2 font-weight-regular card--text">Internet Explorer 9</p></li>
                            <li><p class="body-2 font-weight-regular card--text">Internet Explorer 7 in 8</p></li>
                            <li><p class="body-2 font-weight-regular card--text">Safari</p></li>
                        </ul>
                        <p class="body-2 font-weight-regular card--text">Če spremenite, ali izbrišete brskalnikovo datoteko s piškotki, spremenite ali nagradite vaš brskalnik ali napravo, boste morda morali ponovno onemogočiti piškotke. Postopek za upravljanje in brisanje piškotkov se razlikuje od brskalnika do brskalnika. Če pri tem potrebujete pomoč, lahko pogledate v brskalnikovi pomoči uporabnikom. Sledenju Google Analytics lahko onemogočite tudi na naslednji povezavi.</p>  

                        <h3>Piškotki, uporabljeni na tem spletnem mestu</h3>
                        <div class="div__divider__5"></div> 
                        <ul>
                            <li><p class="body-2 font-weight-regular card--text">Google Analytics - spremlja in beleži interakcijo uporabnikov spletnega mesta in nam pomaga izboljšati uporabniško izkušnjo in spremljanje uspešnosti.</p></li>
                            <li><p class="body-2 font-weight-regular card--text">HTTP only cookie, kamor se shrani JWT token</p></li>
                        </ul>

                        <h3>Google Analytics</h3>
                        <div class="div__divider__5"></div> 
                        <p class="body-2 font-weight-regular card--text">Ta spletna stran uporablja Google Analytics, storitev spletnega analitike, ki jo zagotavlja Google Inc. (»Google«). Uporablja Google Analytics “piškotke”, besedilne datoteke, ki so shranjene v računalniku, in omogočajo analizo uporabe vaše spletne strani. Podatki, ki jih piškotek pridobi o vaši uporabi te spletne strani, se običajno prenesejo na Googlov strežnik, kjer se shranijo. V primeru aktiviranja IP anonimizacije na tej spletni strani bo vaš naslov IP od Googla okrnjen v državah članicah Evropske unije ali drugih pogodbenih straneh Sporazuma o Evropskem gospodarskem prostoru.</p>
                        <p class="body-2 font-weight-regular card--text">Samo v izjemnih primerih bo celoten naslov IP prenesen na Googlov strežnik v ZDA. Google bo v imenu upravljavca te spletne strani uporabil podatke, da oceni vašo uporabo spletnega mesta, zbira poročila o dejavnostih spletnega mesta in jih posreduje operaterju spletne strani. V sklopu storitve Google Analytics velja, da vaš naslov IP ne bo združen z drugimi podatki od Googla.</p>
                        <p class="body-2 font-weight-regular card--text">Piškotke lahko zavrnete s prilagajanjem programske opreme brskalnika; vendar vas opozarjamo, da je lahko v tem primeru izkušnja spletne strani slabša. Podatke, ki jih piškotek ustvari o vaši uporabi spletne strani (vključno z vašim naslovom IP), lahko onemogočite tako, da jo prenesete naslednji vtičnik brskalnika in ga namestite: http://tools.google.com/dlpage/gaoptout?hl=sl .</p>

                        <h3>Informacije o izbrisu</h3>
                        <div class="div__divider__5"></div> 
                        <p class="body-2 font-weight-regular card--text">Vedno imate pravico do brezplačnih informacij o shranjenih osebnih podatkih, poreklu ter namenu obdelave podatkov in pravico do popravljanja, blokiranja ali brisanja teh podatkov.</p>
                            
                        
                    </v-flex>
                </v-layout>

            </v-container>
          </v-card-text>
        <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="close()" color="error" text><v-icon left>mdi-close</v-icon>Zapri</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
</template>

<script>

export default {
    name: 'info-pogoji-uporabe',

    data: () => ({
        title: 'Pogoji uporabe in zasebnost'
    }),

    computed: {
        dialog: {
            set(dialogState) {
                this.$store.commit('SET_INFO_POGOJI_UPORABE', dialogState)
            }, 

            get() {
                return this.$store.state.infoPogojiUporabe
            }
        }
    },

    methods: {
        close() {
            this.$store.commit('SET_INFO_POGOJI_UPORABE', false)
        }
    },

    mounted() {
    },

    destroyed() {

    }
}

</script>